.fileImportModal {
  .fileWrapper {
    margin-top: 20px;
    padding: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    h5 {
      margin: 0px;
    }
  }
}
