.filePreview {
  height: 150px;
  overflow: hidden;
  position: relative;
  background-color: #212121;
  img {
    min-height: initial;
    min-height: initial;
    height: initial;
    height: initial;
  }
  video {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-color: #212121;
  }
}
.fileWrapper {
  padding: 0px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 0px;
  &:nth-child(4n) {
    margin-right: 0px;
    width: calc((100% / 12) * 3);
    min-width: calc((100% / 12) * 3);
  }
  .fileMetaData {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    flex: auto;
    h4 {
      margin: 0px;
    }
  }
  svg {
    cursor: pointer;
  }
}
