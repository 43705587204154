.importSessionModal {
  .modalContent {
    .inputWrapper {
      padding-top: 0px;
      input {
        padding: 30px;
        margin: 0px;
      }
    }
  }
  .importCross {
    width: 40px;
    height: 40px;
    background-color: #00764e;
    border-radius: 5px;
    cursor: pointer;
  }
  .importSessionsList {
    margin: 0px;
    padding: 0px;
    li {
      min-height: min-content;
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}
.modalSearch,
.modalHeader,
.modalSessionsList {
  min-height: min-content;
}
.modalActionsWrapper {
  position: sticky;
  bottom: 0px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
