.newTreatmentForm {
  h4 {
    margin: 0px;
  }
  .inputWrapper {
    p {
      margin-left: 15px;
    }
  }
  .newTreatmentSchedule {
    margin: 20px 0px;
    .dayHead {
      margin-bottom: 20px;
      h3 {
        font-size: 28px;
        margin: 0px;
      }
      h5 {
        font-size: 16px;
        color: #888988;
      }
      .deleteAction {
        padding: 0px 20px;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    .addStepWrapper {
      position: relative;
      .addStepList {
        position: absolute;
        top: 100%;
        background-color: #fff;
        border-radius: 5px;
        min-width: max-content;
        border: 1px solid #e4e4e4;
        display: none;
        &.active {
          display: flex;
        }
        li {
          padding: 20px;
          cursor: pointer;
          border-bottom: 1px solid #e4e4e4;
          &:last-child {
            border: none;
          }
        }
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    .whitebox {
      margin-top: 0px;
      h3 {
        margin-bottom: 0px;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
      }
    }
    .listItemWrapper {
      background-color: white;
      border: 1px solid #e4e4e4;
      margin-bottom: 10px;
      &.emptyItem {
        padding: 20px;
      }
      .dropdownBackdrop {
        cursor: default;
      }
      .whiteBoxEditWrapper {
        cursor: pointer;
      }
      .dragIconWrapper,
      .whiteBoxEditWrapper {
        padding: 20px;
        position: relative;
      }
    }
  }
}
.react-mde {
  margin-top: 20px;
}
.dayActionsRow {
  margin-bottom: 20px;
  .btn {
    &:first-child {
      margin-right: 10px;
    }
  }
}
.sessionActionRow {
  padding: 30px;
  min-height: min-content;
  min-height: -moz-min-content;
  .btn {
    max-width: max-content;
  }
}
.addADay {
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  h2 {
    margin: 0px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .addIcon {
    padding-right: 20px;
  }
}
.availabilityWrapper {
  padding: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: min-content;
  h3 {
    font-size: 16px;
  }
  .bubblesWrapper {
    flex-wrap: wrap;
  }
  .dayBubble {
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 100px;
    cursor: pointer;
    &.active {
      background-color: #000;
      color: #fff;
      border-color: #000;
    }
  }
}

.videoImportBtn {
  margin-left: 10px;
  align-self: flex-end;
  padding: 16px 20px;
  min-width: fit-content;
  min-width: -moz-fit-content;
}
.treatmentContainer {
  height: 100%;
  .treatmentSidebar {
    width: 350px;
    min-width: 350px;
    height: 100%;
    border-right: 1px solid #e4e4e4;
    .treatmentSidebarHead {
      padding: 30px;
      border-bottom: 1px solid #e4e4e4;
      h2 {
        margin: 0px;
      }
    }
    .whitebox {
      height: 100%;
      border-radius: 0px;
      margin: 0px;
      .viewSwitch {
        .inputWrapper {
          padding: 0px;
          select {
            padding: 20px;
          }
        }
        .layoutSwitch {
          padding: 20px;
          border-right: 1px solid #e4e4e4;
          p {
            padding-left: 5px;
          }
        }
      }
      .inputWrapper {
        select {
          border: none;
          padding: 10px 0px 30px 30px;
          margin-right: 30px;
        }
        input,
        p {
          padding: 10px 30px 30px 30px;
          border: none;
        }
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }
}
.calendarView {
  flex-direction: row;
  .newTreatmentScheduleDay {
    padding: 10px;
    min-width: 30%;
    border-right: 1px solid #e4e4e4;
  }
}
.importField {
  position: relative;
  .btn {
    position: absolute;
    right: 30px;
    top: 22px;
    font-size: 12px;
    padding: 10px 15px;
  }
}
