.authWrapper {
  p {
    margin-top: 10px;
  }
  .authBanner {
    color: #fff;
    background-image: (url('../../assets/img/humanitas/gradientBg.png'));
    background-size: cover;
    p {
      color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }
  .mainBg {
    .logoWrapper {
      display: none;
    }
  }
  .logoWrapper {
    img {
      max-width: max-content;
      max-width: -moz-max-content;
    }
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .authWrapper {
    .authBanner {
      display: none;
    }
    .mainBg {
      .logoWrapper {
        display: flex;
        margin-bottom: 30px;
      }
      &.flex-grid-6 {
        width: 100%;
      }
    }
  }
}
