.listCardBox {
  &:nth-child(4n) {
    margin-right: 0px;
    width: calc((100% / 12) * 3);
    min-width: calc((100% / 12) * 3);
  }
  .whitebox {
    margin-bottom: 0px;
  }
  .cardContent {
    padding: 20px;
    .cardPill {
      padding: 5px 10px;
      font-size: 10px;
      border-radius: 999px;
      background-color: #e5e5e5;
      &.scheduledPill {
        color: white;
        background-color: #f8b0bf;
      }
      &.normalPill {
        background-color: #fcd958;
      }
      &.activePill {
        color: white;
        background-color: #057851;
      }
    }
    h5 {
      margin-top: 10px;
    }
  }
  .cardMeta {
    border-top: 1px solid #e5e5e5;
    .row {
      padding: 20px;
      border-right: 1px solid #e5e5e5;
      &:last-child {
        border: none;
      }
    }
    svg {
      width: 22px;
    }
    p {
      margin-left: 10px;
    }
  }
  .treatmentsRow {
    margin-top: 50px;
    margin-bottom: 50px;
    .whitebox {
      margin-top: 0px;
    }
  }
  .whiteBoxEditWrapper {
    position: relative;
    svg {
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
