* {
  outline: none;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

body {
  flex-direction: column;
  margin: 0px;
  min-height: min-content;
  min-height: -moz-min-content;
  font-family: 'Open Sans', sans-serif;
  background-color: #f3f5f7;
  color: #1d2629;
}

p {
  margin: 0px;
}
h1 {
  font-size: 48px;
  font-weight: 400;
  margin: 0px;
}
h3 {
  font-size: 24px;
  margin: 0px;
}
h5 {
  font-size: 18px;
  margin: 0px;
}
p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  margin: 0px;
  small {
    font-size: 12px;
  }
}
a {
  text-decoration: none;
  display: flex;
}
img {
  min-height: min-content;
  width: 100%;
  display: block;
  min-height: -moz-min-content;
}

// Flex Controls

.flex {
  display: flex;
}

.flexAuto {
  flex: auto;
}

// Content Alignment

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

// Grid
.containerFluid {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.container {
  width: 1140px;
  margin: 0px auto;
  max-width: 100%;
  min-height: 100%;
}

.flex-grid-1 {
  margin-right: 20px;
  width: calc((100% / 12) * 1 - 20px);
  min-width: calc((100% / 12) * 1 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 1);
    min-width: calc((100% / 12) * 1);
  }
}
.flex-grid-2 {
  margin-right: 20px;
  width: calc((100% / 12) * 2 - 20px);
  min-width: calc((100% / 12) * 2 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 2);
    min-width: calc((100% / 12) * 2);
  }
}
.flex-grid-3 {
  margin-right: 20px;
  width: calc((100% / 12) * 3 - 20px);
  min-width: calc((100% / 12) * 3 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 3);
    min-width: calc((100% / 12) * 3);
  }
}
.flex-grid-4 {
  margin-right: 20px;
  width: calc((100% / 12) * 4 - 20px);
  min-width: calc((100% / 12) * 4 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 4);
    min-width: calc((100% / 12) * 4);
  }
}
.flex-grid-5 {
  margin-right: 20px;
  width: calc((100% / 12) * 5 - 20px);
  min-width: calc((100% / 12) * 5 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 5);
    min-width: calc((100% / 12) * 5);
  }
}
.flex-grid-6 {
  margin-right: 20px;
  width: calc((100% / 12) * 6 - 20px);
  min-width: calc((100% / 12) * 6 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 6);
    min-width: calc((100% / 12) * 6);
  }
}
.flex-grid-7 {
  margin-right: 20px;
  width: calc((100% / 12) * 7 - 20px);
  min-width: calc((100% / 12) * 7 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 7);
    min-width: calc((100% / 12) * 7);
  }
}
.flex-grid-8 {
  margin-right: 20px;
  width: calc((100% / 12) * 8 - 20px);
  min-width: calc((100% / 12) * 8 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 8);
    min-width: calc((100% / 12) * 8);
  }
}
.flex-grid-9 {
  margin-right: 20px;
  width: calc((100% / 12) * 9 - 20px);
  min-width: calc((100% / 12) * 9 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 9);
    min-width: calc((100% / 12) * 9);
  }
}
.flex-grid-10 {
  margin-right: 20px;
  width: calc((100% / 12) * 10 - 20px);
  min-width: calc((100% / 12) * 10 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 10);
    min-width: calc((100% / 12) * 10);
  }
}
.flex-grid-11 {
  margin-right: 20px;
  width: calc((100% / 12) * 11 - 20px);
  min-width: calc((100% / 12) * 11 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 11);
    min-width: calc((100% / 12) * 11);
  }
}
.flex-grid-12 {
  margin-right: 20px;
  width: calc((100% / 12) * 12 - 20px);
  min-width: calc((100% / 12) * 12 - 20px);
  &.last-flex {
    margin-right: 0px;
    width: calc((100% / 12) * 12);
    min-width: calc((100% / 12) * 12);
  }
}
.flexWrap {
  flex-wrap: wrap;
}

// Alignment controls

.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

#root {
  height: 100%;
}

.whitebox {
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  margin: 30px 0px;
  border-radius: 5px;
}

// Modal styles
.modal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  &.active {
    display: flex;
  }
  .modalBackdrop {
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
  }
  .modalContent {
    z-index: 1000;
    position: relative;
    background-color: white;
    border-right: 1px solid #e5e5e5;
    color: $mainBlue;
    height: 100%;
    width: 350px;
    overflow: auto;
    .modalContentContainer {
      min-height: min-content;
      min-height: -moz-min-content;
      flex: auto;
      .modalHead {
        padding: 30px;
        border-bottom: 1px solid #e5e5e5;
      }
    }
    h2 {
      margin: 0px;
    }
    h4 {
      margin: 0px;
    }
    .monthSub {
      padding: 10px;
      border-radius: 5px;
      margin-top: 20px;
      cursor: pointer;
      background-color: #f8f8f8;
    }
    .mainSub {
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      margin-top: 10px;
      color: white;
      background-image: linear-gradient(
        135deg,
        $green 0%,
        darken($green, 10%) 100%
      );
    }
    .modalContentColumn {
      padding: 20px;
      h2 {
        margin-top: 0px;
      }
    }
    .inputWrapper {
      border-bottom: 1px solid #e5e5e5;
      input {
        padding: 10px 30px 30px 30px;
        margin-top: 10px;
        border-radius: 5px;
        border: none;
      }
      label {
        margin-left: 30px;
      }
      select {
        border: none;
        padding: 10px 0px 30px 30px;
        margin-right: 30px;
        &:after {
          position: absolute;
          right: 30px;
        }
      }
    }
  }
}
.loadingWrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1001;
}
.notificationBar {
  padding: 20px;
  color: white;
  background-color: #548af4;
  position: absolute;
  width: 100%;
  z-index: 10000;
  .notificationsActions {
    .btn {
      &:first-child {
        margin-right: 20px;
      }
      color: #3a3d43;
      font-weight: 700;
      box-shadow: none;
      max-height: min-content;
      align-self: center;
    }
  }
  &.errorBar {
    background-color: #f25352;
  }
}

.react-datepicker__input-container {
  width: 100%;
  input {
    width: 100%;
  }
}

.mainBg {
  background-image: (url('../../assets/img/humanitas/mainBg.png'));
  background-size: cover;
}

.emptyBox {
  padding: 20px 0px;
}
