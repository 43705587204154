.btn {
  border-radius: 5px;
  background-color: $maingray;
  padding: 15px 30px;
  border: none;
  box-shadow: none;
  color: white;
  font-size: 16px;
  transition: all 0.5s ease;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  &.btnRed {
    background-color: $red;
    &:hover:enabled {
      background-color: darken($red, 10%);
    }
    &:disabled {
      background-color: lighten($red, 20%);
    }
  }
  &.btnGrey {
    color: #272836;
    background-color: $grey;
    &:hover:enabled {
      background-color: darken($grey, 10%);
    }
    &:disabled {
      background-color: lighten($grey, 20%);
    }
  }
  &.btnGreen {
    background-color: $green;
    &:hover:enabled {
      background-color: darken($green, 10%);
    }
    &:disabled {
      background-color: lighten($green, 20%);
    }
  }
  &.btnLined {
    background-color: transparent;
    color: $green;
    box-shadow: none;
    border: 2px solid $green;
    svg {
      margin-right: 10px;
      margin-bottom: 1px;
    }
    &:hover:enabled,
    &.active {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      background-color: white;
      border-color: white;
    }
    &:disabled {
      background-color: lighten($green, 20%);
    }
  }
  &.btnBlue {
    background-color: $blue;
    &:hover:enabled {
      background-color: darken($blue, 10%);
    }
    &:disabled {
      background-color: lighten($blue, 20%);
    }
  }
}
.firstBtn {
  button {
    margin-right: 10px;
  }
}
