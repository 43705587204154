@import './var.scss';

.inputWrapper {
  width: 100%;
  padding-top: 30px;
  label {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
  }
  a {
    color: $green;
    font-size: 14px;
    margin-right: 30px;
  }
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
    padding: 15px 15px 15px 15px;
    margin-top: 10px;
    font-size: 14px;
    color: $mainBlue;
  }
  input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
    padding: 20px 40px;
    font-size: 14px;
    color: $mainBlue;
  }
  &.inputErrorWrapper {
    input {
      border: 1px solid #f25352;
    }
    label {
      color: #f25352;
    }
    select {
      border: 1px solid #f25352;
    }
    .bp3-input {
      border: 1px solid #f25352;
    }
  }
  .inputError {
    font-size: 12px;
    font-weight: bold;
    color: #f25352;
    padding-top: 5px;
    display: none;
    &:empty {
      display: none;
      &:before {
        content: '\200b';
      }
    }
  }
  &:last-child {
    input {
      border-bottom: none;
    }
  }
}

.searchBar,
.filterBar {
  margin-right: 20px;
  border: 1px solid #e4e4e4;
  background-color: white;
  .iconWrapper {
    padding: 20px 20px;
    border-left: 1px solid #e4e4e4;
  }
  svg {
    height: 20px;
  }
  input {
    padding: 20px;
    border-radius: 0px;
    border: none;
  }
}
.filterBar {
  &:last-child {
    margin-right: 0px;
  }
  select {
    border: none;
    padding: 20px 0px 20px 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .iconWrapper {
    border-left: none;
    border-right: 1px solid #e4e4e4;
    p {
      margin-left: 20px;
      display: flex;
      align-items: center;
    }
  }
}
