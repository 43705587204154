.progressWrapper {
  margin-top: 15px;
}
.progressLine {
  height: 15px;
  width: 100%;
  border-radius: 5pt;
  background-color: #bbb;
}
.progress {
  height: 15px;
  border-radius: 5pt;
  background-color: #548af4;
}
.percents {
  flex: 1;
  text-align: right;
}
