@import 'assets/styles/var.scss';
.sidebarWrapper {
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  padding: 0px;
  width: 100px;
  min-width: 100px;
  transition: all 0.5s ease;
  z-index: 10000;
  &.expanded {
    width: 200px;
    min-width: 200px;
    .align-center {
      align-items: flex-start;
      ul {
        li {
          padding-left: 20px;
          opacity: 0.5;
          transition: all 0.5s ease;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .justify-center,
    li {
      justify-content: flex-start;
    }
    span {
      margin-left: 10px;
      width: initial;
      opacity: 1;
    }
    .logoWrapper {
      padding: 50px 20px 0px 20px;
    }
  }
  .logoWrapper {
    padding-top: 50px;
  }
  ul {
    padding: 0px;
    margin: 50px 0px;
    li {
      list-style: none;
      display: flex;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 24px;
        min-width: 24px;
      }
      span {
        color: $mainBlue;
        width: 0px;
        display: flex;
        overflow: hidden;
        transition: all 1s ease;
        opacity: 0;
        white-space: nowrap;
      }
      a {
        opacity: 0.5;
        padding: 15px 20px;
        color: rgba(29, 38, 41, 0.5);
        transition: all 0.5s ease;
        &:hover {
          opacity: 1;
        }
        &.active {
          span {
            font-weight: 700;
          }
          opacity: 1;
          cursor: default;
        }
      }
    }
  }
}
