.dashboardWrapper {
  height: 100%;
  max-height: 100%;
}
.dashboardOverflowContainer {
  min-height: 100%;
  position: relative;
  overflow: auto;
}
.dashboardContent {
  min-height: min-content;
  min-height: -moz-min-content;
  padding: 30px 50px;
}

.dropdownBackdrop {
  z-index: 100;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  &.active {
    display: block;
  }
}
.dropdownWrapper {
  position: absolute;
  min-width: min-content;
  min-height: min-content;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: none;
  right: 0px;
  z-index: 1000;
  &.active {
    display: block;
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      min-width: 130px;
      padding: 10px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: #f25352;
      }
    }
  }
}
