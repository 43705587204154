.headerBar {
  margin-bottom: 50px;
  .avatar {
    font-size: 16px;
    width: 48px;
    height: 48px;
    border-radius: 99px;
    background-color: #eaeaea;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }
  .headActions {
    margin-right: 20px;
  }
  .pointer {
    cursor: pointer;
  }
}
