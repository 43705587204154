.modal {
  .modalContent {
    .multiChoicesWrapper {
      .inputWrapper {
        &:last-child {
          border-left: 1px solid #eeeeee;
        }
        select {
          padding: 10px 0px 28px 30px;
        }
      }
      .deleteWrapper {
        border-bottom: 1px solid #eeeeee;
        border-left: 1px solid #eeeeee;
      }
      svg {
        cursor: pointer;
      }
    }
  }
}
.newTreatmentForm {
  .dropdownWrapper {
    ul {
      li {
        margin: 0px;
        padding: 10px;
        &:last-child {
          padding-bottom: 10px;
        }
      }
    }
  }
}
.surveyQuestion {
  .listItemWrapper {
    .flexAuto {
      padding: 20px;
    }
  }
  .dropdownWrapper {
    li {
      padding: 10px !important;
      margin: 0px !important;
    }
  }
}
